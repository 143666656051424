@media only screen and (min-width: 1200px) and (max-width: 1500px) {

    /*Main Header One*/
    .main-header-one__top-right-btn {
        display: none;
    }


    /*Header Two*/
    .main-header-two__top-right {
        display: none;
    }

    .main-header-two__top-inner {
        padding-left: 350px;
    }

    .header-social-links-style2 {
        display: none;
    }

    /*Slider Two*/
    .main-slider-two__content {
        padding-left: 150px;
    }

    .main-slider-two__bg {
        width: 550px;
    }

    .main-slider-two .image-layer::before {
        right: 550px;
    }

    .main-slider-two__img {
        right: 0px;
    }


    /*Main Header Three*/
    .main-header-one__bottom-right-btn {
        display: none;
    }


    /*Feature Six*/
    .feature-six__content {
        margin-right: 0px;
    }

    .feature-six__bg {
        width: calc((100% - 120px) / 2);
    }



















}

@media only screen and (min-width: 1350px) and (max-width: 1500px) {

    /*Main Slider Three*/
    .main-slider-three__content {
        padding-left: 100px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1350px) {

    /*Main Slider Three*/
    .main-slider-three__content {
        padding-left: 160px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1800px) {


    /*Portfolio Page Contact*/
    .portfolio-page-contact__bg::before {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: #000000;
        opacity: 0.5;
        content: "";
        z-index: -2;
    }













}

@media only screen and (min-width: 1600px) and (max-width: 1800px) {

    /*Industry Skill One*/
    .industry-skill-one::before {
        right: 0px;
    }

    .industry-skill-one .shape1 {
        display: none;
    }

    .industry-skill-one__bg {
        width: 650px;
    }












}

@media only screen and (min-width: 1400px) and (max-width: 1600px) {

    /*Industry Skill One*/
    .industry-skill-one::before {
        right: 0px;
    }

    .industry-skill-one .shape1 {
        display: none;
    }

    .industry-skill-one__bg {
        width: 550px;
    }

}

@media only screen and (min-width: 1200px) and (max-width: 1700px) {

    /*Brand One*/
    .brand-one::before {
        right: 0px;
        border-bottom-right-radius: 0px;
    }

    /*Cta One*/
    .cta-one__bg::before {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(var(--thm-black-rgb), 0.7);
        content: "";
        z-index: -1;
    }




}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {

    .contact-page .auto-container {
        max-width: 1400px;
    }


    .feature-four .auto-container {
        max-width: 1400px;
    }

    .feature-three .auto-container {
        max-width: 1400px;
    }

    .main-header-two__bottom .auto-container {
        max-width: 1400px;
    }

    .cta-one__bg {
        width: 350px;
    }

    .cta-one .shape1 {
        left: 338px;
        border-left: 12px solid transparent;
        border-right: 25px solid transparent;
    }

    .main-header-two__top-inner {
        padding-left: 190px;
    }

    /*Feature Four*/
    .feature-four__right-content {
        margin-right: -100px;
        padding-left: 30px;
        padding-top: 194px;
        padding-bottom: 194px;
    }

    .mission-height {
        height: 545px;
    }

    .industry-skill-one::before {
        left: 190px;
    }

    .industry-skill-one__content {
        max-width: 700px;
    }

    .feature-six.service-detail img {
        width: 40%;
    }

    .feature-four__right-content h2 {
        font-size: 45px;
    }

    .feature-four__right-content::before {
        top: 0px;
    }

    /*Team Two*/
    .team-two__single-content {
        width: 200px;
    }

    /*Portfolio Two*/
    .portfolio-two__single-img .overlay-content {
        left: 30px;
        right: 30px;
        padding: 57px 30px 51px;
    }

    .portfolio-two__single-img .overlay-content h2 {
        font-size: 18px;
    }

    .portfolio-two__single-img .overlay-content::before {
        left: 40px;
    }

    .portfolio-two__single-img .overlay-content::after {
        right: 60%;
    }


    /*Services Three*/
    .services-three .big-title {
        display: none;
    }




}

@media only screen and (min-width: 1501px) and (max-width: 1750px) {

    /*Header Two*/
    .main-header-two__top-inner {
        /* padding-left: 350px; */
    }

    .main-header-two__top-right .number {
        display: none;
    }


}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {

    /*Main Slider One*/
    .main-slider-one .shape1 {
        right: 18%;
    }

    .main-slider-one .shape3 {
        display: none;
    }


    /*Services Two*/
    .services-two__left-content {
        margin-left: 0px;
        margin-right: 100px;
    }

    .services-two .sec-title-two {
        margin-left: 0px;
    }

    .services-two__img {
        display: none;
    }

    .services-two__right-content {
        margin-right: 0px;
        margin-left: -100px;
    }

    /*Industry Skill One*/
    .industry-skill-one::before {
        right: 0px;
    }

    .industry-skill-one .shape1 {
        display: none;
    }

    .industry-skill-one__bg {
        width: 450px;
    }








}

@media only screen and (min-width: 1200px) and (max-width: 1300px) {

    /*About Two*/
    .about-two__content {
        margin-left: 0px;
        margin-right: 50px;
    }

    .about-two__right {
        margin-left: 0px;
        margin-right: 0px;
    }



}

/*Medium screen*/
@media only screen and (min-width: 992px) and (max-width: 1199px) {

    .auto-container {
        padding: 0px 30px;
    }

    /*Main Header One*/
    .main-header-one__top {
        display: none;
    }

    .main-header-one__bottom {
        margin-top: 0px;
        padding: 35px 0px 35px;
    }

    .main-header-one__bottom-right {
        display: none;
    }

    .main-header-one__bottom .main-menu-box {
        line-height: 0;
    }

    /*Main Slider One*/
    .main-slider-one .shape1 {
        right: 0%;
    }

    .main-slider-one .shape3 {
        display: none;
    }

    /*Header Two*/
    .main-header-two__top {
        display: none;
    }

    .main-header-two__bottom-right {
        display: none;
    }

    .main-header-two__bottom {
        margin-top: 0px;
        padding: 35px 0px 35px;
    }

    .main-header-two__bottom .main-menu .mobile-nav__toggler {
        color: var(--thm-black);
    }

    .main-header-two__bottom .main-menu .mobile-nav__toggler:hover {
        color: var(--thm-base);
    }

    .main-header-two__bottom .main-menu-box {
        line-height: 0;
    }

    .main-header-two__bottom::before {
        display: none;
    }


    

    /*Brand One*/
    .brand-one::before {
        right: 0px;
        border-bottom-right-radius: 0px;
    }

    /*Portfolio One*/
    .tabs-content__img .content-box {
        left: 80px;
        right: 80px;
    }


    /*Cta One*/
    .cta-one__bg::before {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(var(--thm-black-rgb), 0.7);
        content: "";
        z-index: -1;
    }

    .cta-one__content {
        max-width: none;
        width: 100%;
        float: none;
    }

    /*Footer One*/
    .footer-widget__single.ml30 {
        margin-left: 0px;
    }

    .footer-widget__single.mt50 {
        margin-top: 50px;
    }

    .footer-widget__single-newsletter {
        margin-top: 52px;
    }

    /*Slider Two*/
    .main-slider-two__content {
        padding-left: 150px;
    }

    .main-slider-two__bg {
        width: 400px;
    }

    .main-slider-two .image-layer::before {
        right: 400px;
    }

    .main-slider-two__img {
        top: 100px;
        right: -330px;
    }

    .main-slider-two .shape3 {
        right: -160px;
    }

    .main-slider-two__img img {
        width: 60%;
    }


    /*Feature Four*/
    .feature-four__right-content {
        margin-right: 0px;
        padding-left: 50px;
        padding-top: 115px;
        padding-bottom: 124px;
    }

    .feature-four__right-content h2 {
        font-size: 45px;
    }

    .feature-four__right-content::before {
        top: 0px;
    }


    /*About Two*/
    .about-two__content {
        margin-left: 0px;
        margin-right: 0px;
        max-width: 700px;
        width: 100%;
    }

    .about-two__right {
        margin-left: 0px;
        margin-right: 0px;
        max-width: 610px;
        width: 100%;
        margin-top: 60px;
    }

    /*Services Two*/
    .services-two__left-content {
        margin-left: 0px;
        margin-right: 100px;
    }

    .services-two .sec-title-two {
        margin-left: 0px;
    }

    .services-two__img {
        display: none;
    }

    .services-two__right-content {
        margin-right: 0px;
        margin-left: -100px;
    }

    .services-two__left-content-single .inner {
        padding: 97px 40px 41px;
    }

    .services-two__right-content-single {
        padding: 35px 30px 35px;
    }

    .services-two__right-content-single .title-box {
        margin-left: 20px;
    }

    /*Team Two*/
    .team-two__single.mb40 {
        margin-top: 0px;
    }

    /*Portfolio Two*/
    .portfolio-two__single-img .overlay-content {
        left: 30px;
        right: 30px;
        padding: 57px 30px 51px;
    }

    .portfolio-two__single-img .overlay-content h2 {
        font-size: 18px;
    }

    .portfolio-two__single-img .overlay-content::before {
        left: 40px;
    }

    .portfolio-two__single-img .overlay-content::after {
        right: 60%;
    }

    /*Industry Skill One*/
    .industry-skill-one::before {
        right: 0px;
    }

    .industry-skill-one .shape1 {
        display: none;
    }

    .industry-skill-one__bg {
        position: relative;
        display: block;
        min-height: 500px;
        width: 100%;
    }

    .industry-skill-one__content {
        margin-left: 0px;
        margin-top: 50px;
    }


    /*Services Details*/
    .services-details__sidebar {
        max-width: 600px;
        width: 100%;
        margin: 0 auto;
    }

    .services-details__content {
        margin-top: 20px;
    }

    /*Portfolio Page*/
    .portfolio-page__single-img .overlay-content {
        padding: 21px 20px 25px;
    }

    /*Portfolio Details*/
    .portfolio-details__sidebar {
        max-width: 600px;
        width: 100%;
        margin: 0 auto;
    }

    .portfolio-details__content {
        margin-top: 30px;
    }


    /*Contact Page*/
    .contact-page__google-map {
        height: 600px;
    }

    .contact-page__map {
        margin-top: 40px;
    }

    /*Main Header Three*/
    .main-header-three__top {
        display: none;
    }

    .main-header-three__bottom-right {
        display: none;
    }

    .main-header-three__bottom {
        padding: 35px 0px 34px;
    }

    .main-header-three__bottom .main-menu-box {
        line-height: 0;
    }

    /*Main Slider Three*/
    .main-slider-three__content {
        padding-left: 150px;
    }

    .main-slider-three__img img {
        opacity: 0.7;
    }


    /*Services Three*/
    .services-three .big-title {
        display: none;
    }

    .services-three__right-single .inner {
        padding-left: 75px;
    }

    .services-three__right-single {
        padding: 43px 30px 40px;
        padding-right: 20px;
    }

    .services-three__right-single .inner .icon-box .bg-box {
        left: -5px;
    }

    .services-three__right-single .inner .content-box h2 {
        font-size: 15px;
        font-weight: 700;
    }

    .services-three__right-single .inner .content-box p {
        font-size: 16px;
    }

    /*Feature Six*/
    .feature-six__content {
        margin-top: 40px;
        margin-right: 0px;
        float: none;
    }

    .feature-six__bg {
        position: relative;
        bottom: 0px;
        width: calc((100% - 0px) / 1);
        min-height: 600px;
    }


    /*Faq Two*/
    .faq-two__title h2 {
        font-size: 70px;
    }


}

/*Tablet Layout*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .auto-container {
        padding: 0px 30px;
    }

    /*Main Header One*/
    .main-header-one__top {
        display: none;
    }

    .main-header-one__bottom {
        margin-top: 0px;
        padding: 35px 0px 35px;
    }

    .error-page .error-code {
        font-size: 120px;
        line-height: 120px;
    }

    .main-header-one__bottom-right {
        display: none;
    }

    .main-header-one__bottom .main-menu-box {
        line-height: 0;
    }

    .service-height {
        height: 685px;
    }

    .contact-height{
        height: auto;
    }

    /*Header Two*/
    .main-header-two__top {
        display: block;
    }

    .cta-one__bg,
    .cta-one .shape1 {
        display: none;
    }

    .main-header-two__bottom-right {
        display: block;
        padding-right: 0px;
    }

    .logo-box-one {
        margin-top: 0px;
        padding-bottom: 0px;
    }

    .main-header-two__bottom {
        margin-top: 0px;
        padding: 5px 0px 5px;
    }

    .main-slider .tp-caption {
        display: none;
    }

    .main-header-two__bottom {
        background: #ffffff;
    }

    .main-header-two__bottom .main-menu .mobile-nav__toggler {
        color: var(--thm-black);
    }

    .main-header-two__bottom .main-menu .mobile-nav__toggler:hover {
        color: var(--thm-base);
    }

    .main-header-two__bottom .main-menu-box {
        line-height: 0;
    }

    .main-header-two__bottom::before {
        display: none;
    }


    /*Main Slider One*/
    .main-slider-one .shape1 {
        right: 0%;
    }

    .main-slider-one .shape3 {
        display: none;
    }

    .main-slider-one .shape2 {
        display: none;
    }


    /*Brand One*/
    .brand-one::before {
        right: 0px;

    }

    .portfolio-two {
        padding: 70px 0px 80px;
    }

    /*Portfolio One*/
    .tabs-content__img .content-box {
        left: 80px;
        right: 80px;
    }

    .portfolio-one__tab-box {
        display: block;
    }

    .portfolio-one__top {
        display: block;
        text-align: center;
    }

    .portfolio-one__top .btn-box {
        margin-top: 20px;
    }

    .portfolio-one__inner .tabs-content {
        margin-top: 40px;
        margin-left: 0px;
    }


    /*Cta One*/
    .cta-one__bg::before {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(var(--thm-black-rgb), 0.7);
        content: "";
        z-index: -1;
    }

    .cta-one__content {
        max-width: none;
        width: 100%;
        float: none;
        display: unset;
    }

    .about-two .shape4 {
        display: none;
    }

    /*Footer One*/
    .footer-widget__single.ml30 {
        margin-left: 0px;
    }

    .footer-widget__single.mt50 {
        margin-top: 50px;
    }

    .footer-widget__single-newsletter {
        margin-top: 52px;
        padding: 48px 40px 44px;
    }

    .checked-box label {
        font-size: 14px;
    }


    /*Slider Two*/
    .main-slider-two__content {
        padding-left: 150px;
        z-index: 111;
    }

    .order-sm-2 {
        order: 2;
    }

    .order-sm-1 {
        order: 1;
    }

    .main-slider-two .image-layer::before {
        right: 0px;
    }

    .main-slider-two__bg {
        display: none;
    }

    .main-slider-two__img {
        top: 0px;
        right: -330px;
    }

    .main-slider-two .shape3 {
        display: none;
    }

    .main-slider-two__img img {
        width: 80%;
        opacity: 0.5;
    }

    /*Feature Four*/
    .feature-four__right-content {
        margin-right: 0px;
        padding-left: 50px;
        padding-top: 110px;
        padding-bottom: 110px;
    }

    .about-section-home {
        padding: 70px 0px 70px;
    }

    .me-xxl-2-9 {
        margin-right: 0rem;
    }

    .pb-sm-12 {
        padding-top: 50px;
    }

    .mission-height {
        height: auto;
    }

    .feature-four__right-content h2 {
        font-size: 65px;
    }

    .feature-four__right-content::before {
        top: 0px;
    }


    /*About Two*/
    .about-two__content {
        margin-left: 0px;
        margin-right: 0px;
        max-width: 700px;
        width: 100%;
    }

    .about-two__right {
        margin-left: 0px;
        margin-right: 0px;
        max-width: 610px;
        width: 100%;
        margin-top: 60px;
    }

    .about-imgs {
        display: flex;
        justify-content: center;
    }

    /*Services Two*/
    .services-two__left-content {
        margin-left: 0px;
        margin-right: 0px;
    }

    .services-two .sec-title-two {
        margin-left: 0px;
    }

    .services-two__img {
        display: none;
    }

    .services-two__right-content {
        margin-right: 0px;
        margin-left: 0px;
    }

    .services-two__left-content-single .inner {
        padding: 97px 40px 41px;
    }

    .services-two__right-content-single {
        max-width: 500px;
        width: 100%;
        margin: 0 auto 30px;
    }

    /*Team Two*/
    .team-two__single.mb40 {
        margin-top: 0px;
    }

    .team-two__single-content {
        width: 250px;
    }

    /*Portfolio Two*/
    .portfolio-two .owl-theme .owl-nav {
        right: 10%;
    }

    /*Feature Three*/
    .feature-three__single {
        width: 50%;
        margin-bottom: 40px;
    }

    .feature-three__single:last-child {
        width: 100%;
    }

    .feature-three__single:last-child {
        margin-bottom: 0;
    }

    /*Industry Skill One*/
    .industry-skill-one::before {
        right: 0px;
    }

    .industry-skill-one .shape1 {
        display: none;
    }

    .industry-skill-one__bg {
        position: relative;
        display: block;
        min-height: 500px;
        width: 100%;
    }

    .industry-skill-one__content {
        margin-left: 0px;
        margin-top: 50px;
    }


    /*Services Details*/
    .services-details__sidebar {
        max-width: 600px;
        width: 100%;
        margin: 0 auto;
    }

    .services-details__content {
        margin-top: 20px;
    }

    /*Portfolio Page*/
    .portfolio-page__top {
        display: block;
        text-align: center;
    }

    .portfolio-page__top .btn-box {
        margin-top: 15px;
    }


    /*Portfolio Details*/
    .portfolio-details__sidebar {
        max-width: 600px;
        width: 100%;
        margin: 0 auto;
    }

    .portfolio-details__content {
        margin-top: 30px;
    }


    /*Portfolio Page Team*/
    .portfolio-page--team {
        padding: 0px 0px 90px;
    }

    /*Blog page*/
    .sidebar {
        max-width: 500px;
        width: 100%;
        margin: 0 auto 50px;
    }

    /*Contact Page*/
    .contact-page__top-single {
        max-width: 500px;
        width: 100%;
        margin: 0 auto 30px;
    }

    /*Contact Page*/
    .contact-page__google-map {
        height: 600px;
    }

    .contact-page__map {
        margin-top: 40px;
    }

    /*Main Header Three*/
    .main-header-three__top {
        display: none;
    }

    .main-header-three__bottom-right {
        display: none;
    }

    .main-header-three__bottom {
        padding: 35px 0px 34px;
    }

    .main-header-three__bottom .main-menu-box {
        line-height: 0;
    }

    /*Main Slider Three*/
    .main-slider-three__content {
        padding-left: 140px;
    }

    .main-slider-three__img img {
        opacity: 0.7;
    }

    .main-slider-three__content .title h2 {
        font-size: 55px;
    }


    /*Services Three*/
    .services-three .big-title {
        display: none;
    }

    .services-three__left {
        max-width: 500px;
        width: 100%;
        margin: 0 auto 30px;
    }

    .services-three__right-single {
        max-width: 100%;
        width: 100%;
        margin: 0 auto 30px;
    }

    .industry-skill-one::before {
        content: none;
    }

    .feature-six.service-detail img {
        display: none;
    }

    /*Feature Six*/
    .feature-six__content {
        margin-top: 0px;
        margin-right: 0px;
        float: none;
    }

    .feature-six {
        padding: 50px 0px 70px;
    }

    .feature-six__bg {
        position: relative;
        bottom: 0px;
        width: calc((100% - 0px) / 1);
        min-height: 600px;
    }




    /*Faq Two*/
    .faq-two__title h2 {
        font-size: 60px;
    }



}


/*Mobile Layout*/
@media only screen and (max-width: 575px) {

    .auto-container {
        padding: 0px 15px;
    }

    .about-section-home {
        padding: 70px 0px 70px;
    }

    .mission-height {
        height: auto;
    }

    .contact-height{
        height: auto;
    }

    .about-two .shape4{
        display: none;
    }

    .sec-title h2 {
        font-size: 35px;
    }

    .sec-title h2 br {
        display: none;
    }

    .error-page .error-code {
        font-size: 100px;
        line-height: 100px;
    }

    .thm-btn {
        padding: 0px 30px 0px;
    }

    /*Main Header One*/
    .main-header-one__top {
        display: none;
    }

    .main-header-one__bottom {
        margin-top: 0px;
        padding: 35px 0px 35px;
    }

    .main-header-one__bottom-right {
        display: none;
    }

    .main-header-one__bottom .main-menu-box {
        line-height: 0;
    }

    /*Header Two*/
    .main-header-two__top .header-social-links-style2 {
        display: none;
    }

    .main-header-two__top-inner {
        text-align: center;
        justify-content: center;
    }

    .main-header-two__contact-info li {
        display: block;
        margin-right: 0px;
    }

    .main-header-two__contact-info li .inner {
        justify-content: center;
    }

    .main-header-two__bottom-right {
        display: block;
        padding-right: 0px;
    }

    .main-header-two__bottom {
        margin-top: 0px;
        padding: 5px 0px 5px;
    }

    .main-header-two__top {
        padding: 4px 0px 4px;
    }

    .arrow-down {
        display: none;
    }

    .main-header-two__bottom {
        background: #ffffff;
    }

    .main-header-two__bottom.stricky-fixed{
        position: fixed;
        z-index: 991;
        top: 0;
        left: 0;
        background-color: #fff;
        width: 100%;
        visibility: visible;
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-transition: visibility 500ms ease, -webkit-transform 500ms ease;
        transition: visibility 500ms ease, -webkit-transform 500ms ease;
        transition: transform 500ms ease, visibility 500ms ease;
        transition: transform 500ms ease, visibility 500ms ease, -webkit-transform 500ms ease;
        box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    }

    .logo-box-one {
        margin-top: 0px;
        padding-bottom: 0px;
    }

    .main-header-two__bottom .main-menu .mobile-nav__toggler {
        color: var(--thm-black);
    }

    .main-header-two__bottom .main-menu .mobile-nav__toggler:hover {
        color: var(--thm-base);
    }

    .main-header-two__bottom .main-menu-box {
        line-height: 0;
    }

    .main-header-two__bottom::before {
        display: none;
    }

    .main-slider .tp-caption {
        display: none;
    }

    .feature-four__inner {
        margin-top: 0px;
        padding: 60px 0px 50px;
    }

    /*Main Slider One*/
    .main-slider-one .shape1 {
        right: 0%;
    }

    .main-slider-one .shape3 {
        display: none;
    }

    .main-slider-one .shape2 {
        display: none;
    }

    .main-slider__nav {
        display: none;
    }

    .main-slider-one .shape1 {
        display: none;
    }

    .main-slider-one__content .title h2 {
        font-size: 50px;
    }

    .main-slider-one__content .title h2 br {
        display: none;
    }

    .main-slider-one .image-layer::before {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: #000000;
        opacity: 0.7;
        content: "";
        z-index: -1;
    }

    /*Services One*/
    .services-one__single {
        max-width: 500px;
        margin: 0 auto 30px;
    }

    .service-height {
        height: auto;
    }

    /*Brand One*/
    .brand-one::before {
        right: 0px;
    }

    /*Portfolio One*/
    .tabs-content__img .content-box {
        left: 20px;
        right: 20px;
        padding: 30px 15px 26px;
    }

    .portfolio-one__tab-box {
        display: block;
    }

    .portfolio-one__top {
        display: block;
        text-align: center;
    }

    .portfolio-one__top .btn-box {
        margin-top: 20px;
    }

    .portfolio-one__inner .tabs-content {
        margin-top: 40px;
        margin-left: 0px;
    }

    .portfolio-one__tab-menu {
        padding: 38px 30px 41px;
    }

    .portfolio-one__tab-menu .title h2 {
        font-size: 40px;
    }

    .tabs-content__img .content-box .text-box h2 {
        font-size: 19px;
        line-height: 29px;
    }

    .tabs-content__img .content-box .btn-box a {
        width: 50px;
        height: 50px;
    }


    /*Cta One*/
    .cta-one__content {
        display: block;
        max-width: none;
        width: 100%;
        float: none;
        text-align: center;
    }

    .cta-one__content .btn-box {
        margin-top: 25px;
    }

    .cta-one__bg {
        display: none;
    }

    .cta-one .shape1 {
        display: none;
    }


    /*Footer One*/
    .footer-widget__single-about {
        margin-bottom: 50px;
    }

    .footer-widget__single.ml30 {
        margin-left: 0px;
    }

    .footer-widget__single.mt50 {
        margin-top: 50px;
    }

    .footer-widget__single-newsletter {
        margin-top: 52px;
        padding: 48px 30px 44px;
    }

    .checked-box label {
        font-size: 14px;
    }

    .footer-one__bottom .bottom-inner {
        display: block;
        text-align: center;
    }

    .footer-one__bottom .bottom-inner .copyright {
        margin-bottom: 15px;
    }


    /*Slider Two*/
    .main-slider-two__img {
        display: none;
    }

    .main-slider-two .image-layer::before {
        right: 0px;
    }

    .main-slider-two__bg {
        display: none;
    }

    .main-slider-two .shape2 {
        display: none;
    }

    .main-slider-two .shape3 {
        display: none;
    }

    .main-slider-two__list ul li {
        font-size: 18px;
    }

    .main-slider-two__content .title h2 {
        font-size: 50px;
    }

    .main-slider-two__content .title h2 br {
        display: none;
    }

    .main-slider__nav.style2 {
        display: none;
    }

    /*Feature Four*/
    .feature-four__right-content {
        margin-right: 0px;
        padding-left: 50px;
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .vision-changebg .owl-nav .owl-next,
    .vision-changebg .owl-nav .owl-prev {
        height: 45px;
        width: 45px;
    }

    .sec-title-two {
        padding-bottom: 30px;
    }

    .portfolio-two {
        padding: 70px 0px 70px;
    }

    .scroll-to-top {
        width: 50px;
        height: 50px;
        line-height: 50px;
    }

    .portfolio-details .owl-carousel .owl-nav button.owl-prev {
        top: 35%;
        width: 45px;
        height: 45px;
    }

    .portfolio-details .owl-carousel .owl-nav button.owl-next {
        top: 35%;
        width: 45px;
        height: 45px;
    }

    .feature-four__right-content h2 {
        font-size: 54px;
    }

    .feature-four__inner_home {
        padding: 70px 0px 70px;
    }

    .vision-wrapper {
        border-right: none;
    }

    .feature-four__right-content::before {
        top: 0px;
    }

    .feature-four__single h2 a br {
        display: none;
    }


    /*Sec Title Two*/
    .sec-title-two h2 {
        font-size: 35px;
    }

    .sec-title-two h2 br {
        display: none;
    }

    /*About Two*/
    .about-two__content {
        margin-left: 0px;
        margin-right: 0px;
        max-width: 700px;
        width: 100%;
    }

    .about-two__right {
        margin-left: 0px;
        margin-right: 0px;
        max-width: 610px;
        width: 100%;
        margin-top: 60px;
    }

    .about-two__content-bottom {
        display: block;
    }

    .about-two__content-bottom .btn-box {
        margin-left: 0px;
        margin-top: 15px;
    }

    .about-two__right-single {
        max-width: 500px;
        width: 100%;
        margin: 0 auto 30px;
    }

    .about-two__right-single.mb30 {
        margin-top: 0px;
    }

    /*Services Two*/
    .services-two__left-content {
        margin-left: 0px;
        margin-right: 0px;
    }

    .services-two .sec-title-two {
        margin-left: 0px;
    }

    .services-two__img {
        display: none;
    }

    .services-two__right-content {
        margin-right: 0px;
        margin-left: 0px;
    }

    .services-two__left-content-single .inner {
        padding: 97px 40px 41px;
    }

    .services-two__right-content-single {
        max-width: 500px;
        width: 100%;
        margin: 0 auto 30px;
    }

    .services-two__left-content-single {
        max-width: 500px;
        width: 100%;
        margin: 0 auto 30px;
    }

    .services-two__left-content-single.mb30 {
        margin-top: 0px;
    }

    .services-two__left-content-single .inner h2 {
        font-size: 20px;
    }

    /*Team Two*/
    .team-two__single.mb40 {
        margin-top: 0px;
    }

    .team-two__single-content {
        width: 210px;
    }

    .team-two__single {
        max-width: 500px;
        width: 100%;
        margin: 0 auto 30px;
    }

    /*Portfolio Two*/
    .portfolio-two__single-img .overlay-content {
        left: 30px;
        right: 30px;
        padding: 57px 30px 51px;
    }

    .portfolio-two__single-img .overlay-content h2 {
        font-size: 18px;
    }

    .portfolio-two__single-img .overlay-content::before {
        left: 40px;
    }

    .portfolio-two__single-img .overlay-content::after {
        right: 60%;
    }

    .portfolio-two .owl-theme .owl-nav {
        display: none;
    }

    /*Feature Three*/
    .feature-three__single {
        width: 100%;
        margin-bottom: 40px;
    }

    .feature-three__single:last-child {
        margin-bottom: 0;
    }


    /*Industry Skill One*/
    .industry-skill-one::before {
        content: none;
    }

    .industry-skill-one .shape1 {
        display: none;
    }

    .industry-skill-one__bg {
        position: relative;
        display: block;
        min-height: 500px;
        width: 100%;
    }

    .industry-skill-one__content {
        margin-left: 0px;
        margin-top: 50px;
    }



    /*page Header*/
    .page-header__inner h2 {
        font-size: 40px;
    }


    /*Services Details*/
    .services-details__sidebar {
        max-width: 500px;
        width: 100%;
        margin: 0 auto;
    }

    .services-details__content {
        margin-top: 20px;
    }

    .sidebar__category-list li a {
        font-size: 17px;
        line-height: 30px;
        font-weight: 700;
        padding: 16px 15px 18px;
    }

    .services-details__content-text-box3 {
        display: block;
    }


    .services-details__content-text-box3 .content-box {
        margin-left: 0px;
        margin-top: 30px;
    }

    /*Portfolio Page*/
    .portfolio-page__top {
        display: block;
        text-align: center;
    }

    .portfolio-page__top .btn-box {
        margin-top: 15px;
    }

    .portfolio-page__single {
        max-width: 500px;
        width: 100%;
        margin: 0 auto 30px;
    }

    .portfolio-page__single-img .overlay-content {
        padding: 21px 20px 25px;
    }

    .styled-pagination li {
        margin-right: 10px;
    }


    /*Portfolio Details*/
    .portfolio-details__sidebar {
        max-width: 500px;
        width: 100%;
        margin: 0 auto;
    }

    .portfolio-details__content {
        margin-top: 30px;
    }

    .portfolio-details__sidebar-list li .left-box {
        width: 85px;
    }

    .portfolio-details__sidebar-list li .right-box p {
        font-size: 17px;
    }

    .portfolio-details__sidebar-list li .left-box p {
        font-size: 17px;
    }

    .portfolio-details__sidebar-list li .right-box ul li a {
        font-size: 17px;
    }

    .portfolio-details__content-text-box2 {
        display: block;
    }

    .portfolio-details__content-text-box2 .content-box {
        margin-left: 0px;
        margin-top: 20px;
    }

    .portfolio-details__content-img3 .single-box {
        max-width: 500px;
        width: 100%;
        margin: 0 auto 30px;
    }


    /*Blog page*/
    .sidebar {
        max-width: 500px;
        width: 100%;
        margin: 0 auto 50px;
    }


    .comment-one {
        margin-top: 12px;
    }

    .comment-one__image {
        position: relative;
        top: 0px;
        margin-bottom: 10px;
    }

    .comment-one__single {
        padding-left: 0px;
    }

    .comment-one__content::before {
        display: none;
    }

    .comment-one__content {
        padding-left: 0px;
    }

    /*Contact Page*/
    .contact-page__top-single {
        max-width: 500px;
        width: 100%;
        margin: 0 auto 30px;
    }

    /*Contact Page*/
    .contact-page__google-map {
        height: 500px;
    }

    .contact-page__map {
        margin-top: 40px;
    }

    .contact-page__form .title-box h2 {
        font-size: 35px;
        line-height: 45px;
    }

    .contact-page__form .title-box h2 br {
        display: none;
    }

    /*Main Header Three*/
    .main-header-three__top {
        display: none;
    }

    .main-header-three__bottom-right {
        display: none;
    }

    .main-header-three__bottom {
        padding: 35px 0px 34px;
    }

    .main-header-three__bottom .main-menu-box {
        line-height: 0;
    }

    /*Main Slider Three*/
    .main-slider-three__img {
        display: none;
    }

    .main-slider-three__content .title h2 {
        font-size: 35px;
    }

    .main-slider-three__content .title h2 br {
        display: none;
    }

    .main-slider-three__content-bottom .video-box {
        margin-left: 15px;
    }


    .sec-title-three h2 {
        font-size: 35px;
        line-height: 45px;
    }

    .sec-title-three h2 br {
        display: none;
    }


    /*Services Three*/
    .services-three .big-title {
        display: none;
    }

    .services-three__left {
        max-width: 500px;
        width: 100%;
        margin: 0 auto 30px;
    }

    .services-three__right-single {
        max-width: 500px;
        width: 100%;
        margin: 0 auto 30px;
    }

    .services-three__top {
        display: block;
    }

    .services-three__top .counter-box {
        margin-top: 30px;
    }

    .services-three__right-single .inner {
        padding-left: 75px;
    }

    .services-three__right-single {
        padding: 43px 30px 40px;
        padding-right: 20px;
    }

    .services-three__right-single .inner .icon-box .bg-box {
        left: -5px;
    }

    .services-three__right-single .inner .content-box h2 {
        font-size: 22px;
        font-weight: 700;
    }

    .services-three__right-single .inner .content-box p {
        font-size: 18px;
    }

    .feature-six.service-detail img {
        display: none;
    }

    .service-detail .service-tabs .tab-btns .tab-btn {
        width: 49%;
        padding: 14px 10px 14px;
    }

    /*Feature Six*/
    .feature-six__content {
        margin-top: 0px;
        margin-right: 0px;
        float: none;
    }

    .feature-six {
        padding: 50px 0px 70px;
    }

    .styled-pagination {
        padding-top: 10px;
    }

    .feature-six__bg {
        position: relative;
        bottom: 0px;
        width: calc((100% - 0px) / 1);
        min-height: 350px;
    }

    .feature-six__content-text2-single {
        margin-top: 30px;
    }

    .feature-six__content-text2 {
        margin-top: 21px;
    }


    /*Prices plan One*/
    .prices-plan-one__single {
        max-width: 400px;
        width: 100%;
        margin: 0 auto 30px;
        padding: 33px 30px 50px;
    }

    .prices-plan-one__single .category-wrapper p {
        font-size: 14px;
        font-weight: 500;
    }

    /*Faq Two*/
    .faq-two__title h2 {
        font-size: 40px;
    }



}

/*Order Box One*/
@media only screen and (max-width: 991px) {
    .order-22 {
        -ms-flex-order: 2;
        order: 2;
    }

    .order-11 {
        -ms-flex-order: 1;
        order: 1;
    }

}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {

    .main-slider h2{
        font-size: 62px!important;
    }
}